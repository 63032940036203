
.table-row {
    border-bottom: 1px solid var(--v-grey-lighten4);
}
small {
    font-size: 1.2rem;
    line-height: 16px;
    color: var(--v-grey-lighten1);
    padding: 0 16px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
