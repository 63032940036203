
::v-deep {
    .v-btn.v-btn--text.v-size--small {
        height: auto;
        padding: 6px;
        margin: -6px;
        font-size: 1.4rem;
        font-weight: 400;
    }
    .theme--light.v-btn--active:not(:hover):before {
        opacity: 0;
    }
    .theme--light.v-btn--active:hover:before {
        opacity: 0.04;
    }
}
