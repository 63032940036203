
::v-deep {
    .v-text-field {
        height: auto !important;
        min-height: 36px !important;
        .v-input__slot {
            margin-bottom: 0 !important;
            min-height: initial !important;
        }
        .v-messages__message {
            margin-bottom: 4px;
        }
    }
    @media (min-width: 768px) {
        .v-text-field {
            min-height: 48px !important;
        }
    }
}
